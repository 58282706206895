<template>
  <div class="home industry_home Commercial_home">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/landed.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <div class="introduce ">
      <div class="padding-tb70">
        <div class="WJgroup-titleBox flex">
          <p class="WJgroup-title">{{$t('property.title')}}</p>
        </div>
      </div>
    </div>
    <el-row class="introduce paddingb150 commercial_wrap" type="flex" justify="center">
      <el-col class="leftIno">
        <div>
          <router-link tag="a" to="/IndustryHouse" class="noactive ">
            <span class="font">{{$t('property.href1')}}</span>
          </router-link>
        </div>
        <div>
          <router-link tag="a" to="/IndustryCommercial" class="noactive activeBox">
            <span class="font">{{$t('property.href2')}}</span>
          </router-link>
        </div>
        <div>
          <router-link tag="a" to="/IndustryProperty" class="noactive">
            <span class="font">{{$t('property.href3')}}</span>

          </router-link>
        </div>
      </el-col>
      <el-col class="rightIno  ">
        <div class="houseItitle flex align-end">
<!--     商业地产     -->
          <img class="houseItitleImg1" src="../../assets/image/Industry/img2.png" alt="">
          <h6>{{$t('property.href2')}}</h6>
          <p>{{$t('property.href2Desc')}}
            <img class="houseItitleImg2" src="../../assets/image/Industry/Star1.png" alt="">
          </p>
        </div>
        <div class="CommercialOne flex">
          <template v-for="(item,index) in newsList1">
            <div :class="{CommercialOL:index==0 ,CommercialOR:index==1}" :key="index" @click="goto(item)">
              <img :src="item.litpic" alt="">
              <p class="Commercialp" >{{item.title}}</p>
            </div>
          </template>
        </div>
        <div class="CommercialTwo flex justify_between">
          <template v-for="(item,index) in newsList2">
          <div class="CommercialTL" :class="{CommercialTL:index==0 ,CommercialTM:index==1,CommercialTR:index==2}" :key="index" @click="goto(item)">
            <img :src="item.litpic" alt="">
            <p class="Commercialp">{{item.title}}</p>
          </div>
          </template>
        </div>


      </el-col>
    </el-row>
    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },
        slideList: [{
            id: "42",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          }
        ],
        menuActiveIndex: '/Industry',
        menu: {
          name: '产业布局',
          path: '/Industry',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.layout'),
          path: '/Industry',
        }, {
          name: this.$t('menu.Property'),
          path: '/Industry',
        }, {
          name: this.$t('property.href2'),
          path: '/IndustryCommercial',
        }],
        typeid:107,
        newsList:[],
        newsList1:[],
        newsList2:[]
      };
    },
    computed: {},
    created() {
      this.getData();
    },
    mounted() {
      new this.$wow.WOW().init()
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.newsList=[];
        this.$request.post(api,{
          apiArclist_1:`ekey=1&typeid=${this.typeid}&limit=100,&addfields=title_en`,//获取栏目文章列表
        }).then(res=>{
          this.newsList =this.$utils.translate(res.apiArclist[1].data);
          this.newsList1 =this.newsList.slice(0,2);
          this.newsList2 = this.newsList.slice(2,5);
        })
      },
      goto(item){
        this.$router.push({
          path:'/IndustryHouseDetail',
          query:{id:item.aid}
        })
      },

      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .swiperIMg {
    width: 100%;
    height: auto;
  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }

  .content {
    overflow: hidden;
  }

  .introduce {
    justify-content: center;
    max-width: 1400px;
    margin:0 auto;

    .leftIno {
      width: 312px;

      .noactive {
        display: inline-block;
        width: 100%;
        height: 116px;
        background: linear-gradient(90deg, #ADAFB2 0%, #8F9294 100%);
        // font-family: 'OPPOSans-M';
        font-style: normal;
        font-weight: 400;
        font-size: 29px;
        line-height: 116px;
        margin-bottom: 1px;
        padding-left: 50px;
        color: #FFFFFF;

      }

      .activeBox {
        height: 162px;
        background: linear-gradient(90deg, #E3CAA6 0%, #B5956F 100%);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 28px;
          right: 0;
          width: 0;
          height: 0;
          border-right: 15px solid #fff;
          border-bottom: 15px solid transparent;
          border-top: 15px solid transparent;
          border-left: 15px solid transparent;
        }
      }
    }

    .rightIno {
      width: 986px;
      margin-left: 102px;

      .house_img {
        margin-top: 50px;
        position: relative;
        overflow: hidden;

        &:hover {
          .house_img_content {
            top: 0px;
          }

          .cover {
            opacity: 0;
          }
        }

        .house_img_content {
          position: absolute;
          top: -100%;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.19);
          // opacity: 0;
          transition: 0.5s;
          // top: 83px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .conFont {
          cursor: pointer;
          text-align: center;

          h2 {
            height: 28px;
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          p {
            height: 15px;
            margin-top: 18px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          img {
            margin-top: 21px;
          }
        }

        .cover {
          transition: 0.5s;
          width: 985px;
          height: 60px;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          bottom: 0px;
          display: flex;

          h2 {
            height: 18px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-left: 29px;
          }

          p {
            width: 185px;
            height: 14px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-left: 23px;
          }
        }
      }


    }
  }



  .IndustryLookMore {
    width: 218px;
    height: 51px;
    border: 1px solid #A4825F;
    border-radius: 73px;
    margin: 88px auto 61px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 51px;
    text-align: center;
    color: #A4825F;
    cursor: pointer;
  }

  .CommercialOne {
    width: 100%;
    height: 310px;
    overflow: hidden;
    margin-top: 43px;
    margin-bottom: 46px;
    position: relative;
  }

  .CommercialOne div img{
    transition: 2s;
  }
  .CommercialOne div img:hover{
      transform: scale(1.1);
  }
  .CommercialOne .CommercialOL {
    cursor: pointer;
    width: 55%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%);
    position: relative;
  }

  .CommercialOne .CommercialOR {
     cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    height: 100%;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);

  }

  .CommercialOne .CommercialOL img {
    height: 100%;
    width: auto;

  }

  .CommercialTwo div img{
    transition: 2s;
  }
  .CommercialTwo div img:hover{
      transform: scale(1.1);
  }
  .CommercialTwo{
    width: 100%;
    height: 310px;
    overflow: hidden;
    // margin-bottom: 46px;
    position: relative;
  }
   .Commercialp{
     position: absolute;
     bottom: 0;
     left: 0;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height:  60px;
    color: #FFFFFF;
    padding: 0 20px;
    box-sizing: border-box;
   }

  .CommercialTL{
    cursor: pointer;
    width: 40%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
    background-color: #A4825F;
    position: relative;
  }
  .CommercialTR{
    width: 40%;
    height: 100%;
    position: relative;

     clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
    .CommercialTR p{
        text-align: right;
    }
  .CommercialTM{
    width: 38.5%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 31%;
    overflow: hidden;
    background-color: #A4825F;
        clip-path: polygon(26% 0%, 100% 0%, 73% 100%, 0% 100%);
  }
  .CommercialTM p{
    width: 120%;
    left: -20%;
    text-align: center;


  }
.CommercialTM img{

   margin-left: -1%;
}
  @media screen and (min-width:320px) and (max-width:767px) {
    .industry_home .introduce{
      display: block;
      padding: 0 20px;
      height: auto;
    }
    .industry_home .introduce .leftIno{
      width: 100%!important;
      display: flex;
      float: unset;
      margin-bottom: 40px;
    }
    .introduce .leftIno .noactive{
      justify-content: center;
      padding-left: 0;
      font-size: 39px;
    }
    .introduce .leftIno .activeBox{
      justify-content: center;
      padding-left: 0;
      font-size: 39px;
      height:116px;
    }

    .industry_home .introduce .leftIno div{
      width: 33%;
      text-align: center;
    }
    .industry_home .introduce .rightIno{
      width: 100%!important;
      margin-left: 0;
      float: unset;
    }

    .introduce .rightIno .title h2{
      font-size: 34px;
    }
    .introduce .rightIno .title p{
      font-size: 28px;
      line-height: 40px;
    }
    .commercial_wrap{
      min-height:calc(100vh - 600px);
    }
  //  ---------------------------
    .houseItitle{
      padding-bottom: 60px;
    }
    .houseItitle h6{
      font-size: 39px;
    }
    .houseItitle p{
      top:80px;
      font-size: 34px;
      line-height: 44px;
    }
   .commercial_wrap .CommercialOR{
      img{
        width: 100%;
      }
    }
    .commercial_wrap .CommercialTL{
      img{
        width: 100%;
      }
   }
    .commercial_wrap .Commercialp{
      height: 80px;
      line-height: 80px;
      font-size: 34px;
    }
  }
</style>
